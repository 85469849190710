import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://c33746910749428b9c875eb75275ff7a@o1102974.ingest.us.sentry.io/6129609",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});